import "@/css/profile.css";
import React, { useEffect, useState } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Content from "@/components/change-password/content";
import { API, isLoggedIn, logout } from "@/api/auth";

const ChangePassword = ({ params }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (isLoggedIn()) {
      try {
        setLoading(true);
        const response = await API.get("/api/client/o/get");
        // if (response.data.status == "error") navigate("/");
        // else setData(response.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Zmiana hasla">
          <HeaderOneTop />
          <HeaderOne />
          <Content />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ChangePassword;
